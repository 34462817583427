import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* PAGES */
import DashboardLayout from "../layout/index";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import ContactUs from "../pages/dashboard/contact-us";
import BookAnAppointment from "../pages/dashboard/book-an-appointment";
import LandingPage from "../pages/dashboard/landing-page";
import ProgramInformation from "../pages/dashboard/program-information";
import ApplyOnline from "../pages/dashboard/apply-online";
import TranscriptInformation from "../pages/dashboard/transcript-information";
import Blogs from "../pages/dashboard/blogs";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/create-account" element={<Register />} />
        </Routes>

        {/* DASHBOARD ROUTES */}
        <Routes>
          <Route
            path="/contact-us"
            element={
              <DashboardLayout>
                <ContactUs />
              </DashboardLayout>
            }
          />
          <Route
            path="/book-an-appointment"
            element={
              <DashboardLayout>
                <BookAnAppointment />
              </DashboardLayout>
            }
          />
          <Route
            path="/landing-page"
            element={
              <DashboardLayout>
                <LandingPage />
              </DashboardLayout>
            }
          />
          <Route
            path="/apply-online"
            element={
              <DashboardLayout>
                <ApplyOnline />
              </DashboardLayout>
            }
          />
          <Route
            path="/program-information"
            element={
              <DashboardLayout>
                <ProgramInformation />
              </DashboardLayout>
            }
          />
          <Route
            path="/transcript-information"
            element={
              <DashboardLayout>
                <TranscriptInformation />
              </DashboardLayout>
            }
          />
          <Route
            path="/blogs"
            element={
              <DashboardLayout>
                <Blogs />
              </DashboardLayout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;