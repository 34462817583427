/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import moment from "moment";

/* ICON IMPORTS */
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";

/* Form Control */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import {
  deleteTranscriptForm,
  editTranscriptForm,
  getTranscriptForm,
} from "../../../service/api";
import { useEffect } from "react";

const findUpper = (string1, string2) => {
  let extractedString = [];
  function extractLetter(string) {
    for (var i = 0; i < string.length; i++) {
      if (string.charAt(i) !== " ") {
        return string.charAt(i).toUpperCase();
      }
    }
  }

  extractedString[0] = extractLetter(string1);
  extractedString[1] = extractLetter(string2);
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};
/* Validation Schema  */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  middleName: yup.string().required("Middle Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup.string().email().required("Email Address is Required"),
  phone: yup.number().required("Phone Number is Required"),
  address: yup.string().required("Address is Required"),
  suiteNumber: yup.string(),
  city: yup.string().required("City is Required"),
  state: yup.string().required("State is Required"),
  zipCode: yup.string().required("Zip Code is Required"),
  country: yup.string().required("Country is Required"),
  studentIdNumber: yup.string().required("Student Id Number is Required"),
  totalCopiesRequested: yup.number(),
  sendToEmailAddress: yup.string().email(),
  attn: yup.string(),
  sendToAddress: yup.string(),
  sendToSuiteNumber: yup.string(),
  sendToCity: yup.string(),
  sendToState: yup.string(),
  sendToZipCode: yup.string(),
  sendToCountry: yup.string(),
  status: yup.number(),
});
const Users = () => {
  /* MODAL STATES */
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const columns = [
    {
      name: "User",
      grow: "2",
      selector: (row) => (
        <div className="user-detail">
          <div className="avatar">
            {findUpper(row.firstName, row?.lastName)}
          </div>
          <h5>
            {row.firstName} {row?.lastName}
          </h5>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => <span className="light-text">{row.phone}</span>,
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row.email}</span>,
    },
    {
      name: "Created At",
      selector: (row) => (
        <span className="light-text">
          {moment(row?.createdAt).format("MMM, DD YYYY, hh:mm A")}
        </span>
      ),
    },

    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewModal(!viewModal);
              setViewData(row);
            }}
          />
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditData(row);
              setEditModal(!editModal);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            onClick={() => {
              setDeleteModal(!deleteModal);
              setDeleteId(row?._id);
            }}
            className="action-btn"
          />
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="pagination-loading-screen">
        <p>Please wait!</p>
        <p>We are fetching data </p>
      </div>
    );
  };

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [fetchData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [search, setSearch] = useState(null);

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getTranscriptFormData();
  };
  const handlePageChange = async (e) => {
    setPage(e);
    getTranscriptFormData();
  };

  const [viewData, setViewData] = useState(null);
  const getTranscriptFormData = () => {
    let params;
    if (search) {
      params = {
        page: page,
        sizePerPage: sizePerPage,
        search,
      };
    } else {
      params = {
        page: page,
        sizePerPage: sizePerPage,
      };
    }
    setLoading(true);
    getTranscriptForm(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        console.log(res);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((error) => {
        console.log("Error While Fetching Transcript Form List", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [deleteId, setDeleteId] = useState(null);
  const handleDelete = () => {
    deleteTranscriptForm(deleteId)
      .then((res) => {
        getTranscriptFormData();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
      });
  };

  const [editData, setEditData] = useState(null);
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    if (editData !== null) {
      Object.entries(editData).forEach((entry) => {
        const [key, value] = entry;
        console.log("KEY", key);
        console.log("VALUE", value);
        if (validationSchema?.fields?.hasOwnProperty(key)) {
          formikEditForm.setFieldValue(key, value);
        }
        if (key === "sendTo") {
          formikEditForm.setFieldValue("sendToAddress", value.address);
          formikEditForm.setFieldValue("sendToSuiteNumber", value.suiteNumber);
          formikEditForm.setFieldValue("sendToCity", value.city);
          formikEditForm.setFieldValue("sendToState", value.state);
          formikEditForm.setFieldValue("sendToZipCode", value.zipCode);
          formikEditForm.setFieldValue("sendToCountry", value.country);
          formikEditForm.setFieldValue("sendToEmailAddress", value.email);
        }
      });
    }
  }, [editData]);

  /* FORM VALUES HANDLING */
  const formikEditForm = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      suiteNumber: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      studentIdNumber: "",
      totalCopiesRequested: "",
      attn: "",
      sendToEmailAddress: "",
      sendToAddress: "",
      sendToSuiteNumber: "",
      sendToCity: "",
      sendToState: "",
      sendToZipCode: "",
      sendToCountry: "",
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let obj = {
        firstName: values?.firstName,
        middleName: values?.middleName,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phone,
        address: values?.address,
        city: values?.city,
        state: values?.state,
        zipCode: values?.zipCode,
        country: values?.country,
        studentIdNumber: values?.studentIdNumber,
        totalCopiesRequested: values?.totalCopiesRequested,
        attn: values?.attn,
        sendToEmailAddress: values?.sendToEmailAddress,
        sendToAddress: values?.sendToAddress,
        sendToCity: values?.sendToCity,
        sendToState: values?.sendToState,
        sendToZipCode: values?.sendToZipCode,
        sendToCountry: values?.sendToCountry,
        status: values?.status,
      };
      if (values?.suiteNumber) {
        obj.suiteNumber = values?.suiteNumber;
      }
      if (values?.sendToSuiteNumber) {
        obj.sendToSuiteNumber = values?.sendToSuiteNumber;
      }
      setEditLoading(true);
      console.log("values", values);
      editTranscriptForm(editData?._id, obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            getTranscriptFormData();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setEditLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setEditLoading(false);
        });
    },
  });

  useEffect(() => {
    getTranscriptFormData();
  }, [page, sizePerPage, search]);

  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>Transcript Form Page Leads</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your contacts us page leads and edit their
                information
              </p>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name"
                  name="searchUser"
                  id="searchUser"
                  onChange={(e) => {
                    console.log("text", e.target.value);
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? fetchData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                pagination
                paginationServer
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handlePerPageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* View MODAL */}
      <Modal
        centered
        backdrop="static"
        show={viewModal}
        onHide={() => setViewModal(!viewModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Transcript Form Details</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>
            <strong>Name: </strong>
            {viewData?.firstName} {viewData?.middleName} {viewData?.lastName}
          </p>
          <p>
            <strong>Email: </strong>
            {viewData?.email}
          </p>
          <p>
            <strong>Phone: </strong>
            {viewData?.phone}
          </p>
          <p>
            <strong>Address: </strong>
            {viewData?.address},{" "}
            {viewData?.suiteNumber ? viewData?.suiteNumber + ", " : ""}{" "}
            {viewData?.city}, {viewData?.state} - {viewData?.zipCode}{" "}
            {viewData?.country}
          </p>
          <p>
            <strong>Student ID Number: </strong>
            {viewData?.studentIdNumber}
          </p>
          <p>
            <strong>Total Copies Requested: </strong>
            {viewData?.totalCopiesRequested}
          </p>
          <p>
            <strong>Attn: </strong>
            {viewData?.attn}
          </p>
          <p>
            <strong>Send To Email: </strong>
            {viewData?.sendTo?.email}
          </p>
          <p>
            <strong>Send To Address: </strong>
            {viewData?.sendTo?.address},{" "}
            {viewData?.sendTo?.suiteNumber
              ? viewData?.sendTo?.suiteNumber + ", "
              : ""}{" "}
            {viewData?.sendTo?.city}, {viewData?.sendTo?.state} -{" "}
            {viewData?.sendTo?.zipCode} {viewData?.sendTo?.country}
          </p>
          <p>
            <strong>Created At: </strong>{" "}
            {moment(viewData?.createdAt).format("MMM, DD YYYY, hh:mm A")}
          </p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => {
              setViewData(null);
              setViewModal(!viewModal);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        centered
        backdrop="static"
        show={deleteModal}
        onHide={() => setDeleteModal(!deleteModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Confirm Delete</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>Are you sure you want to delete these contact details ?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => {
              setViewData(null);
              setDeleteModal(!deleteModal);
            }}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT USER MODAL */}
      <Modal
        centered
        size="lg"
        show={editModal}
        onHide={() => setEditModal(!editModal)}
      >
        <form
          onSubmit={formikEditForm.handleSubmit}
          id="edit-form"
          className="add-user-form"
        >
          <Modal.Header className="add-user-modal-header">
            <h5>Edit Contact</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {/* FIRST NAME */}
              <Col lg="4" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.firstName}
                    defaultValue={formikEditForm?.values?.firstName}
                  />
                  {formikEditForm.errors.firstName &&
                    formikEditForm.touched.firstName && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.firstName}
                      </small>
                    )}
                </div>
              </Col>
              {/* MIDDLE NAME */}
              <Col lg="4" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Middle Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="middleName"
                    id="middleName"
                    placeholder="First Name"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.middleName}
                    defaultValue={formikEditForm?.values?.middleName}
                  />
                  {formikEditForm.errors.middleName &&
                    formikEditForm.touched.middleName && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.middleName}
                      </small>
                    )}
                </div>
              </Col>
              {/* LAST NAME */}
              <Col lg="4" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="lName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.lastName}
                    defaultValue={formikEditForm?.values?.lastName}
                  />
                  {formikEditForm.errors.lastName &&
                    formikEditForm.touched.lastName && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.lastName}
                      </small>
                    )}
                </div>
              </Col>
              {/* EMAIL ADDRESS */}
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="placeholder@roundtechsquare.com"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.email}
                    defaultValue={formikEditForm?.values?.email}
                  />
                  {formikEditForm.errors.email &&
                    formikEditForm.touched.email && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.email}
                      </small>
                    )}
                </div>
              </Col>
              {/* PHONE NUMBER */}
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    className="phone-control"
                    onChange={(e) => {
                      formikEditForm.setFieldValue("phone", e);
                    }}
                    value={formikEditForm?.values?.phone}
                    defaultValue={formikEditForm?.values?.phone}
                  />
                  {formikEditForm.errors.phone &&
                    formikEditForm.touched.phone && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.phone}
                      </small>
                    )}
                </div>
              </Col>
              {/* ADDRESS */}
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    id="address"
                    placeholder="Address"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.address}
                    defaultValue={formikEditForm?.values?.address}
                  />
                  {formikEditForm.errors.address &&
                    formikEditForm.touched.address && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.address}
                      </small>
                    )}
                </div>
              </Col>
              {/* SUITE NUMBER */}
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="suiteNumber"
                    id="suiteNumber"
                    placeholder="Address Line 2"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.suiteNumber}
                    defaultValue={formikEditForm?.values?.suiteNumber}
                  />
                  {formikEditForm.errors.suiteNumber &&
                    formikEditForm.touched.suiteNumber && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.suiteNumber}
                      </small>
                    )}
                </div>
              </Col>
              {/* City */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    placeholder="City"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.city}
                    defaultValue={formikEditForm?.values?.city}
                  />
                  {formikEditForm.errors.city &&
                    formikEditForm.touched.city && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.city}
                      </small>
                    )}
                </div>
              </Col>
              {/* STATE */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    placeholder="state"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.state}
                    defaultValue={formikEditForm?.values?.state}
                  />
                  {formikEditForm.errors.state &&
                    formikEditForm.touched.state && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.state}
                      </small>
                    )}
                </div>
              </Col>
              {/* ZIPCODE */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    ZipCode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="zipCode"
                    id="zipCode"
                    placeholder="zipCode"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.zipCode}
                    defaultValue={formikEditForm?.values?.zipCode}
                  />
                  {formikEditForm.errors.zipCode &&
                    formikEditForm.touched.zipCode && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.zipCode}
                      </small>
                    )}
                </div>
              </Col>
              {/* COUNTRY */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    id="country"
                    placeholder="country"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.country}
                    defaultValue={formikEditForm?.values?.country}
                  />
                  {formikEditForm.errors.country &&
                    formikEditForm.touched.country && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.country}
                      </small>
                    )}
                </div>
              </Col>

              {/* STUDENT ID NUMBER */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Student ID Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="studentIdNumber"
                    id="studentIdNumber"
                    placeholder="Student ID Number"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.studentIdNumber}
                    defaultValue={formikEditForm?.values?.studentIdNumber}
                  />
                  {formikEditForm.errors.studentIdNumber &&
                    formikEditForm.touched.studentIdNumber && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.studentIdNumber}
                      </small>
                    )}
                </div>
              </Col>
              {/* TOTAL NUMBER OF COPIES */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Total Copies Requested
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="totalCopiesRequested"
                    id="totalCopiesRequested"
                    placeholder="Total Copies Requested"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.totalCopiesRequested}
                    defaultValue={formikEditForm?.values?.totalCopiesRequested}
                  />
                  {formikEditForm.errors.totalCopiesRequested &&
                    formikEditForm.touched.totalCopiesRequested && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.totalCopiesRequested}
                      </small>
                    )}
                </div>
              </Col>

              {/* SEND TO EMAIL ADDRESS */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Send To Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sendToEmailAddress"
                    id="sendToEmailAddress"
                    placeholder="Email Address"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.sendToEmailAddress}
                    defaultValue={formikEditForm?.values?.sendToEmailAddress}
                  />
                  {formikEditForm.errors.sendToEmailAddress &&
                    formikEditForm.touched.sendToEmailAddress && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.sendToEmailAddress}
                      </small>
                    )}
                </div>
              </Col>
              {/* ATTN */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Attn
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="attn"
                    id="attn"
                    placeholder="Email Address"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.attn}
                    defaultValue={formikEditForm?.values?.attn}
                  />
                  {formikEditForm.errors.attn &&
                    formikEditForm.touched.attn && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.attn}
                      </small>
                    )}
                </div>
              </Col>
              <strong>Send to Details: </strong>
              {/* SEND TO ADDRESS */}
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sendToAddress"
                    id="sendToAddress"
                    placeholder="sendToAddress"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.sendToAddress}
                    defaultValue={formikEditForm?.values?.sendToAddress}
                  />
                  {formikEditForm.errors.sendToAddress &&
                    formikEditForm.touched.sendToAddress && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.sendToAddress}
                      </small>
                    )}
                </div>
              </Col>
              {/* SEND TO SUITE NUMBER */}
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sendToSuiteNumber"
                    id="sendToSuiteNumber"
                    placeholder="Address Line 2"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.sendToSuiteNumber}
                    defaultValue={formikEditForm?.values?.sendToSuiteNumber}
                  />
                  {formikEditForm.errors.sendToSuiteNumber &&
                    formikEditForm.touched.sendToSuiteNumber && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.sendToSuiteNumber}
                      </small>
                    )}
                </div>
              </Col>
              {/* SEND TO CITY */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sendToCity"
                    id="sendToCity"
                    placeholder="City"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.sendToCity}
                    defaultValue={formikEditForm?.values?.sendToCity}
                  />
                  {formikEditForm.errors.sendToCity &&
                    formikEditForm.touched.sendToCity && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.sendToCity}
                      </small>
                    )}
                </div>
              </Col>
              {/* SEND TO  STATE */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sendToState"
                    id="sendToState"
                    placeholder="State"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.sendToState}
                    defaultValue={formikEditForm?.values?.sendToState}
                  />
                  {formikEditForm.errors.sendToState &&
                    formikEditForm.touched.sendToState && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.sendToState}
                      </small>
                    )}
                </div>
              </Col>
              {/* SEND TO ZIPCODE */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    ZipCode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sendToZipCode"
                    id="sendToZipCode"
                    placeholder="ZipCode"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.sendToZipCode}
                    defaultValue={formikEditForm?.values?.sendToZipCode}
                  />
                  {formikEditForm.errors.sendToZipCode &&
                    formikEditForm.touched.sendToZipCode && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.sendToZipCode}
                      </small>
                    )}
                </div>
              </Col>
              {/* SEND TO COUNTRY */}
              <Col lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="sendToCountry"
                    id="sendToCountry"
                    placeholder="Country"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.sendToCountry}
                    defaultValue={formikEditForm?.values?.sendToCountry}
                  />
                  {formikEditForm.errors.sendToCountry &&
                    formikEditForm.touched.sendToCountry && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.sendToCountry}
                      </small>
                    )}
                </div>
              </Col>

              {console.log("formikEditForm.errors", formikEditForm.errors)}
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => {
                setEditData(null);
                setEditModal(!editModal);
              }}
            >
              Cancel
            </Button>
            {editLoading ? (
              <Button className="proceed-btn" type="button">
                Please Wait...
              </Button>
            ) : (
              <Button className="proceed-btn" type="submit">
                Save details
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </section>
  );
};

export default Users;
