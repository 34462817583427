import request from "./request";

// auth
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("admin/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const forgotPassword = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/forgot/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Contact Us API
export const getContactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contactUs`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getContactUsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editContactUs = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/contactUs/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteContactUs = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Apply Online API
export const getApplyOnline = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/applyOnline`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getApplyOnlineById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/applyOnline/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editApplyOnline = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/applyOnline/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteApplyOnline = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/applyOnline/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


// Landing Page Form API
export const getLandingPageForm = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/landingPageForm`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getLandingPageFormById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/landingPageForm/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editLandingPageForm = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/landingPageForm/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteLandingPageForm = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/landingPageForm/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Program Information API
export const getProgramInformation = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/programInformation`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getProgramInformationById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/programInformation/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editProgramInformation = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/programInformation/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteProgramInformation = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/programInformation/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Transcript Form API
export const getTranscriptForm = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/transcript`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTranscriptFormById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/transcript/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editTranscriptForm = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/transcript/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteTranscriptForm = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/transcript/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs API
export const addBlog = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/blog`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlog = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blog/`, + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlog = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/blog/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlog = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs Category API
export const addBlogCategory = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/blogCategory`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBlogCategories = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/blogCategory`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBlogCategory = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/blogCategory/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBlogCategory = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/blogCategory/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Book An Appointment API
export const getBookAnAppointment = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/bookAnAppointment`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBookAnAppointmentById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/bookAnAppointment/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBookAnAppointment = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/bookAnAppointment/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBookAnAppointment = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/bookAnAppointment/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};